import * as React from "react"
import { useEffect, useState } from "react"
import dataJSON from '../data/data.js'
import Init from '../common/init'
import './style.css'
import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';

import "swiper/css/navigation";

import { isPhone, isSmallTablet, isTablet, isSmallDesktop,  isDesktop } from '../common/mq'

import Moment from 'moment'


// import required modules
import { Navigation } from "swiper";

import "./style-slide.css";


import Layout from '../layouts/default/layout';

import SectionComponent from '../components/section_component'

import Vimeo from '@u-wave/react-vimeo';



const isBrowser = typeof window !== "undefined"

const IndexPage = () => {

  const [data, setData] = useState({})
  const [pageReady, setPageReady] = useState(false)
  const [heightContent, setHeightContent] = useState(0)
  const [itemsBanner, setItemsBanner] = useState([])

  const [isViewMobile] = useState(isPhone())
  const [isViewTablet] = useState(isSmallTablet() || isTablet())
  const [isViewDesktop] = useState(isSmallDesktop() || isDesktop())

  
  

  const [sectionsForPage] = useState(returnSectionForPage())

  function getHeight(){

    if (!isBrowser) return

    setHeightContent(window.innerHeight-517+'px')



  }

  function getWidthWindow(height=false){
    if (!isBrowser) return

    if(height){
      return window.innerWidth*0.56+'px'
    }

    return window.innerWidth+'px'
  }




  function returnSectionForPage(page = ''){
    let result = []
    Object.keys(dataJSON.app_sections).map(item=>{
     dataJSON.app_sections[item].app_pages.map(item2=>{
       if(item2.primaryDisplay === '/'+page) result.push(dataJSON.app_sections[item])
       return true
     })
     return true
    })

    if(!result.length){

      if(!isBrowser) return result;
      
      if(dataJSON.app_pages.filter(it=>it.page_type==='schedule').length){
        window.location.href =   window.location.origin +  dataJSON.app_pages.filter(it=>it.page_type==='schedule')[0].page_path
      }
      
    }

    return result;
  }

  async function getBanners(){
    return await fetch(`https://api.cms.sistemas.angulare.app/app_carousel?tenant=eq.${dataJSON.customer.tenant}&app_code=eq.web&app_page=eq.home`)
  }

  function redirectTo(link){
    if (!isBrowser) return
    window.location.href = link
  }




  useEffect(async ()=>{
    getHeight()
    setData(dataJSON)  
    
    const result = await (await getBanners()).json()

    result.filter(item=>Moment().isBetween(item.active_from, item.active_to, undefined, '[]'))

    setItemsBanner(result)
    
     
  }, [])

  
  return (
    <>
      <title>{dataJSON.app_pages && dataJSON.app_pages.filter(item=>item.page_path === '/').length ? dataJSON.app_pages.filter(item=>item.page_path === '/')[0].page_title : ''}</title>
      <Init setPageReady={setPageReady} />
      <Layout data={data} style={{display:pageReady?'':'none'}} >

      

      
      {

        (()=>{
          if(itemsBanner.length){
            return(
              
              <div className="">
                <Swiper
                  spaceBetween={50}
                  slidesPerView={1}
                  onSlideChange={() => console.log('slide change')}
                  onSwiper={(swiper) => console.log(swiper)}
                  navigation={true} 
                  modules={[Navigation]} 
                >
                  { 
                  
                  itemsBanner.map((it, itIndex)=>{
                    return(
                      <SwiperSlide key={itIndex}>
                        <img style={{width:'100%', height:'auto', cursor:'pointer'}} 
                          onClick={()=>redirectTo(it.url_web)}
                          src={
                            //'https://casacor.abril.com.br/wp-content/uploads/sites/7/2017/05/20161014-banner-sustentabilidade.jpg'
                            isViewDesktop ? it.image_desktop : isViewTablet ? it.image_tablet : isViewMobile ? it.image_mobile : ''
                          } 
                        ></img>
                      </SwiperSlide>
                    )
                  }) 
                  
                  }
                  
                </Swiper>
              </div>

            )
          }
        })()

      }
      
        <div style={{display:sectionsForPage.length==1&&sectionsForPage[0].section_type=='carroussel'?'none':'flex'}} className="flex flex-col sm:mt-5">
          {
            sectionsForPage.map((dataSectionForPage, key)=>{
              return(
                <SectionComponent key={key} data={dataSectionForPage}/>
              )
            })
          }
        </div>
      </Layout>

    </>
  )
}

export default IndexPage
